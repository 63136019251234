import React from "react";

import "./UserManagementPage.css";

import DataTable from "../../../Components/DataGrid/DataGrid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "fullName", headerName: "Full name", width: 130 },
  { field: "emailId", headerName: "Email Id", width: 130 },
  {
    field: "status",
    headerName: "Status",
  },
  {
    field: "createdDate",
    headerName: "Created Date",
  },
];

const rows = [
  {
    id: 1,
    fullName: "Jon",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 2,
    fullName: "Cersei",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 3,
    fullName: "Jaime",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 4,
    fullName: "Arya",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 5,
    fullName: "Daenerys",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 6,
    fullName: "Test",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 7,
    fullName: "Ferrara",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 8,
    fullName: "Rossini",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
  {
    id: 9,
    fullName: "Harvey",
    status: "active",
    emailId: "test@test.com",
    createdDate: "23/02/2024",
  },
];

const paginationModel = { pstatus: 0, pstatusSize: 50 };

const UserManstatusment = () => {
  return (
    <div className="user-manstatusment-container">
      <DataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        pstatusSizeOptions={[50, 100, 150]}
      />
    </div>
  );
};

export default UserManstatusment;
