import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";

const TextfieldWrapper = ({ name, ...otherProps }) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    InputLabelProps: { shrink: true },
  };

  const isError = meta.touched && meta.error;

  return (
    <FormControl fullWidth variant='outlined' error={isError}>
      <InputLabel htmlFor={name} shrink={true}>
        {otherProps.label}
        {otherProps.required && <span> *</span>}
      </InputLabel>
      <TextField {...configTextfield} id={name} />
      {isError && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

TextfieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TextfieldWrapper;
