import React from "react";
import "../Footer/footer.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function Footer() {
  const { t: translator } = useTranslation();
  const location = useLocation();

  if (location.pathname === "/") {
    return null;
  }

  return (
    <div>
      <footer className='footer'>
        <p> &copy; {translator("footer.copyright")}</p>
      </footer>
    </div>
  );
}

export default Footer;
