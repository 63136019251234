// src/State/store.js
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { rootReducer, watchIncrement } from ".";
import { composeWithDevTools } from "@redux-devtools/extension";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchIncrement);

export default store;
