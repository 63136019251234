import React from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { GoogleLogin } from "@react-oauth/google";
import "./login.css";

function LogInPage() {
  const { handleSuccessLogin, handleErrorLogin } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="container">
      <h2>Login Page</h2>
      <GoogleLogin
        onSuccess={(response) => {
          handleSuccessLogin(response);
          navigate("/admin-panel");
        }}
        onError={handleErrorLogin}
      />
    </div>
  );
}

LogInPage.propTypes = {
  // anchorEl: PropTypes.object,
  // handleClose: PropTypes.func.isRequired,
  // changeLanguage: PropTypes.func.isRequired,
};

export default LogInPage;
