import React from "react";
import PropTypes from "prop-types";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";

import Header from "../Layouts/Header/header";
import Footer from "../Layouts/Footer/footer";

import AdminPanel from "../Pages/AdminPanelPage/adminPanel";

import MainContentWrapper from "../Layouts/MainContent/mainContent";
import { useAuth } from "../Hooks/useAuth";
import UserRegistration from "../Pages/RegistrationPage/userRegistration";
import LogIn from "../Pages/LoginPage/login";

const PrivateRoute = ({ element }) => {
  const { loggedIn } = useAuth();

  return loggedIn ? element : <Navigate to="/signin" replace />;
};

PrivateRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

const Routers = () => {
  return (
    <Router>
      <div>
        <Header />
        <MainContentWrapper>
          <Routes>
            <Route path="/signin" element={<LogIn />} />

            <Route
              path="/register"
              element={<PrivateRoute element={<UserRegistration />} />}
            />

            <Route
              path="/admin-panel"
              element={<PrivateRoute element={<AdminPanel />} />}
            />

            <Route
              path="/"
              element={<Navigate to="/signin" replace={true} />}
            />
          </Routes>
        </MainContentWrapper>
        <Footer />
      </div>
    </Router>
  );
};

export default Routers;
