import React, { useState, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { googleLogout } from "@react-oauth/google";
import { setUser } from "../State/actions";
import { jwtDecode } from "jwt-decode";

const extractUserDetailsFromToken = (token) => {
  const decoded = jwtDecode(token);
  const { name, email } = decoded;
  setUser({ name, email });
};

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleSuccessLogin = (response) => {
    const credential = response?.credential;
    if (credential) {
      localStorage.setItem("access_tokn", credential);
      extractUserDetailsFromToken(credential);
    }
    setLoggedIn(true);
  };

  const handleErrorLogin = (error) => {
    console.error(error);
    alert("Login Failed, Try again later");
  };

  const handleLogout = () => {
    googleLogout();
    localStorage.removeItem("access_token");
    setLoggedIn(false);
    // set user state to null
    // clear credentials from localstorage
  };

  const authContextValue = useMemo(
    () => ({
      loggedIn,
      setLoggedIn,
      handleSuccessLogin,
      handleErrorLogin,
      handleLogout,
    }),
    [loggedIn]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
