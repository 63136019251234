import React from "react";

import "./pipelineManagement.css";

import DataTable from "../../../Components/DataGrid/DataGrid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "projectId", headerName: "Project Id", width: 130 },
  { field: "environment", headerName: "Environment", width: 130 },
  {
    field: "pipelineId",
    headerName: "Pipeline Id",
    width: 90,
  },
  {
    field: "pipelineUrl",
    headerName: "Pipeline Url",
    width: 290,
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 90,
  },
];

const rows = [
  {
    id: 1,
    projectId: "Snow",
    environment: "DEV",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 2,
    projectId: "Lannister",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 3,
    projectId: "Lannister",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 4,
    projectId: "Stark",
    environment: "PROD",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 5,
    projectId: "Targaryen",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 6,
    projectId: "Melisandre",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 7,
    projectId: "Clifford",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 8,
    projectId: "Frances",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
  {
    id: 9,
    projectId: "Roxie",
    environment: "UAT",
    createdDate: "01/05/2024",
    pipelineId: "1",
    pipelineUrl: "https://jenkins.test.com/test",
  },
];

const paginationModel = { pcreatedDate: 0, pcreatedDateSize: 50 };

const PipelineManagement = () => {
  return (
    <div className="about-container">
      <DataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        pcreatedDateSizeOptions={[50, 100, 150]}
      />
    </div>
  );
};

export default PipelineManagement;
