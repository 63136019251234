// src/State/reducers.js
import {
  INCREMENT_COUNT,
  DECREMENT_COUNT,
  RESET_COUNT,
  SET_USER,
} from "./actions";

const initialState = {
  totalCount: 0,
};

const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_COUNT:
      return { ...state, totalCount: state.totalCount + 1 };
    case DECREMENT_COUNT:
      return { ...state, totalCount: state.totalCount - 1 };
    case RESET_COUNT:
      return { ...state, totalCount: 0 };
    default:
      return state;
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export { counterReducer, userReducer };
