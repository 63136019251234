import React from "react";

import DataTable from "../../../Components/DataGrid/DataGrid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "projectId", headerName: "Project Id", width: 130 },
  { field: "userId", headerName: "userId", width: 130 },
  {
    field: "pipelineId",
    headerName: "Pipeline Id",

    width: 90,
  },
  {
    field: "userTitle",
    headerName: "User Title",

    width: 160,
  },
];

const rows = [
  {
    id: 1,
    projectId: "Snow",
    userId: "Jon",
    pipelineId: "35",
    userTitle: "Lead",
  },
  {
    id: 2,
    projectId: "Lannister",
    userId: "Cersei",
    pipelineId: "42",
    userTitle: "Lead",
  },
  {
    id: 3,
    projectId: "Lannister",
    userId: "Jaime",
    pipelineId: "45",
    userTitle: "Lead",
  },
  {
    id: 4,
    projectId: "Stark",
    userId: "Arya",
    pipelineId: "16",
    userTitle: "Architect",
  },
  {
    id: 5,
    projectId: "Targaryen",
    userId: "Daenerys",
    pipelineId: "22",
    userTitle: "Architect",
  },
  {
    id: 6,
    projectId: "Melisandre",
    userId: null,
    pipelineId: "150",
    userTitle: "Architect",
  },
  {
    id: 7,
    projectId: "Clifford",
    userId: "Ferrara",
    pipelineId: "44",
    userTitle: "Architect",
  },
  {
    id: 8,
    projectId: "Frances",
    userId: "Rossini",
    pipelineId: "36",
    userTitle: "Architect",
  },
  {
    id: 9,
    projectId: "Roxie",
    userId: "Harvey",
    pipelineId: "65",
    userTitle: "Architect",
  },
];

const paginationModel = { pagelineId: 0, pageIdSize: 50 };

const JenkinsProjectManagement = () => {
  return (
    <div className="jenkins-project-manpipelineIdment-container">
      <DataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        ppipelineIdSizeOptions={[50, 100, 150]}
      />
    </div>
  );
};

export default JenkinsProjectManagement;
