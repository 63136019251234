import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReorderIcon from "@mui/icons-material/Reorder";
import Logout from "../../Components/SignOut/signOut";
import { useAuth } from "../../Hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import LanguageIcon from "@mui/icons-material/Language";
import "./header.css";

function LanguageMenu({ anchorEl, handleClose, changeLanguage }) {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => changeLanguage("en")}>English</MenuItem>
      <MenuItem onClick={() => changeLanguage("hn")}>हिन्दी</MenuItem>
    </Menu>
  );
}

LanguageMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

function HiddenLinksContent({ translator, handleLinkClick }) {
  return (
    <>
      <Link to="/home" onClick={handleLinkClick}>
        {translator("menu.home")}
      </Link>
      <Link
        to="/about"
        onClick={() => {
          handleLinkClick();
        }}
      >
        {translator("menu.about")}
      </Link>
      <Link to="/register" onClick={handleLinkClick}>
        {translator("menu.register")}
      </Link>
    </>
  );
}

HiddenLinksContent.propTypes = {
  translator: PropTypes.object.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
};

function LoggedInContent({ handleClose, changeLanguage, anchorEl }) {
  return (
    <>
      <Logout />
      <button className="sportsIcon1" onClick={() => handleClose(anchorEl)}>
        <LanguageIcon />
      </button>
      <LanguageMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        changeLanguage={changeLanguage}
      />
    </>
  );
}

LoggedInContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
};

function HiddenLinks({
  translator,
  showHiddenLinks,
  closeHiddenLinks,
  changeLanguage,
}) {
  const { loggedIn } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLinkClick = () => {
    closeHiddenLinks();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`hiddenLinks ${showHiddenLinks ? "show" : ""}`}>
      {showHiddenLinks && (
        <>
          <HiddenLinksContent
            translator={translator}
            handleLinkClick={handleLinkClick}
          />
          {loggedIn && (
            <LoggedInContent
              handleClose={handleClose}
              changeLanguage={changeLanguage}
              anchorEl={anchorEl}
            />
          )}
        </>
      )}
    </div>
  );
}

HiddenLinks.propTypes = {
  translator: PropTypes.object.isRequired,
  showHiddenLinks: PropTypes.bool.isRequired,
  closeHiddenLinks: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

function LinksContainer({ loggedIn }) {
  return (
    <div className="linksContainer">
      {/* <Link to='/home'>{translator('menu.home')}</Link>
      <Link to='/about'>{translator('menu.about')}</Link>
      <Link to='/register'>{translator('menu.register')}</Link> */}
      <Link to="/admin-panel">Admin Panel</Link>
      {/* <Link to="/pipeline-mngt">Pipeline Management</Link> */}

      {loggedIn && <Logout />}
    </div>
  );
}

LinksContainer.propTypes = {
  translator: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

function ToggleButton({ handleToggleHiddenLinks }) {
  return (
    <button className="toggleButton" onClick={handleToggleHiddenLinks}>
      <ReorderIcon className="toggleIcon" />
    </button>
  );
}

ToggleButton.propTypes = {
  handleToggleHiddenLinks: PropTypes.func.isRequired,
};

function SportsIcon({ handleClick }) {
  return (
    <div className="sportsIconWrapper">
      <button onClick={handleClick} className="sportsIcon">
        <LanguageIcon />
      </button>
    </div>
  );
}

SportsIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

function RightSide({
  translator,
  loggedIn,
  handleToggleHiddenLinks,
  changeLanguage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="rightSide">
      <LinksContainer translator={translator} loggedIn={loggedIn} />
      <ToggleButton handleToggleHiddenLinks={handleToggleHiddenLinks} />
      {windowWidth > 900 && <SportsIcon handleClick={handleClick} />}
      <LanguageMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        changeLanguage={changeLanguage}
      />
    </div>
  );
}

RightSide.propTypes = {
  translator: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  handleToggleHiddenLinks: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

function Header() {
  const { loggedIn } = useAuth();
  const { t: translator, i18n } = useTranslation();
  const [showHiddenLinks, setShowHiddenLinks] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    if (language === "en") {
      toast.info(translator("message.hindi_to_english_language"));
    } else {
      toast.info(translator("message.english_to_hindi_language"));
    }
  };

  const handleToggleHiddenLinks = () => {
    setShowHiddenLinks(!showHiddenLinks);
  };

  // const closeHiddenLinks = () => {
  //   setShowHiddenLinks(false);
  // };

  if (location.pathname === "/") {
    console.log(location.pathname);

    return navigate("/signin");
  }

  return (
    <div className="header">
      <div className="leftSide">
        {/* <HiddenLinks
          translator={translator}
          showHiddenLinks={showHiddenLinks}
          closeHiddenLinks={closeHiddenLinks}
          changeLanguage={changeLanguage}
        /> */}
      </div>
      <RightSide
        translator={translator}
        loggedIn={loggedIn}
        handleToggleHiddenLinks={handleToggleHiddenLinks}
        changeLanguage={changeLanguage}
      />
    </div>
  );
}

export default Header;
