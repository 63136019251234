import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { styled } from "@mui/system";
import { useTheme, useMediaQuery, Container, Grid, Typography } from "@mui/material";
import Textfield from "../../Components/FormsUI/Textfield/textfield";
import Button from "../../Components/Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { ThemeProvider} from "@mui/material/styles";


const FormWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const INITIAL_FORM_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  addressLine1: "",
  city: "",
  state: "",
};

/* eslint-disable newline-per-chained-call */
const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid email.").required("Required"),
  phone: Yup.number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
  addressLine1: Yup.string(),
  addressLine2: Yup.string(),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
});

const RenderFormFields = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h6'>User Details Registration</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Textfield name='firstName' label='First Name' required />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Textfield name='lastName' label='Last Name' />
    </Grid>
    <Grid item xs={12}>
      <Textfield name='email' label='Email' required />
    </Grid>
    <Grid item xs={12}>
      <Textfield name='phone' label='Phone' required />
    </Grid>
    <Grid item xs={12}>
      <Textfield name='addressLine1' label='Address Line 1' />
    </Grid>
    <Grid item xs={12}>
      <Textfield name='city' label='City' required />
    </Grid>
    <Grid item xs={12}>
      <Textfield name='state' label='State' required />
    </Grid>

    <Grid item xs={12}>
      <Button label='Submit' />
    </Grid>
  </Grid>
);

const UserRegistration = () => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={muiTheme}>
      <Grid container>
        <Grid item xs={12}>
          <Container maxWidth={isSmallScreen ? "sm" : "md"}>
            <FormWrapper>
              <Formik
                initialValues={{
                  ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={handleSubmit}
              >
                <Form>
                  <RenderFormFields />
                </Form>
              </Formik>
            </FormWrapper>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const handleSubmit = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));
  toast.success("Form submitted successfully");
};

export default UserRegistration;
