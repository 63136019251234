// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import i18n from "i18next";
import App from "./App";
import enTranslation from "./Locales/en.json";
import hnTranslation from "./Locales/hn.json";
import store from "./State/store.js"; // Import the Redux store
import { GoogleOAuthProvider } from "@react-oauth/google";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      translation: enTranslation,
    },
    hn: {
      translation: hnTranslation,
    },
  },
});

const root = createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="460440866465-u5t57eakuoia3t7neud68h7trgsqdrbo.apps.googleusercontent.com">
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </GoogleOAuthProvider>
);
