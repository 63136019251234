import React from "react";

import "./adminPanel.css";

import BasicTabs from "../../Components/Tabs/Tabs";
import UserManagement from "./UserManagementPage/UserManagementPage";
import ProjectManagement from "./ProjectManagementPage/ProjectManagementPage";
import JenkinsProjectManagement from "./JenkinsProjectManagementPage/JenkinsProjectManagementPage";
import PipelineManagement from "./PipelineManagementPage/pipelineManagement";
const tabs = [
  {
    label: "User management",
    value: 0,
    child: <UserManagement />,
  },
  {
    label: "Project Management",
    value: 1,
    child: <ProjectManagement />,
  },
  {
    label: "Jenkins Project Management",
    value: 2,
    child: <JenkinsProjectManagement />,
  },
  {
    label: "Pipeline Management",
    value: 2,
    child: <PipelineManagement />,
  },
];

const AdminPanel = () => {
  return (
    <div className="admin-panel-container">
      <h2 className="admin-panel-heading">Admin Panel Page</h2>

      <BasicTabs tabs={tabs} />
    </div>
  );
};

export default AdminPanel;
