import React from "react";
import { AuthProvider } from "../src/Contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./Routes/routers";
import "./App.css";

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <Routers />
        <ToastContainer />
      </AuthProvider>
    </div>
  );
}

export default App;
