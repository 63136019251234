import React from "react";

import DataTable from "../../../Components/DataGrid/DataGrid";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "projectName", headerName: "Project name", width: 130 },
  { field: "vertical", headerName: "Vertical", width: 130 },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 90,
  },
];

const rows = [
  {
    id: 1,
    projectName: "Snow",
    vertical: "Infra mod",
    createdDate: "01/05/2024",
  },
  {
    id: 2,
    projectName: "Lannister",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 3,
    projectName: "Lannister",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 4,
    projectName: "Stark",
    vertical: "Data mod",
    createdDate: "01/05/2024",
  },
  {
    id: 5,
    projectName: "Targaryen",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 6,
    projectName: "Melisandre",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 7,
    projectName: "Clifford",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 8,
    projectName: "Frances",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
  {
    id: 9,
    projectName: "Roxie",
    vertical: "App Mod",
    createdDate: "01/05/2024",
  },
];

const paginationModel = { pcreatedDate: 0, pcreatedDateSize: 50 };

const ProjectMancreatedDatement = () => {
  return (
    <div className="project-mancreatedDatement-container">
      <DataTable
        rows={rows}
        columns={columns}
        paginationModel={paginationModel}
        pcreatedDateSizeOptions={[50, 100, 150]}
      />
    </div>
  );
};

export default ProjectMancreatedDatement;
