// src/State/index.js
import { combineReducers } from "redux";
import { counterReducer, userReducer } from "./reducers";
import { watchIncrement } from "./sagas";

const rootReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
});

export { rootReducer, watchIncrement };
