// src/State/sagas.js
import { takeEvery, put } from "redux-saga/effects";
import { INCREMENT_COUNT } from "./actions";

function* incrementSaga() {
  yield put({ type: INCREMENT_COUNT });
}
export function* watchIncrement() {
  yield takeEvery("INCREMENT_ASYNC", incrementSaga);
}
